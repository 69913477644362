// interactive
import './interactive/SmoothScroll';
import './interactive/NavbarFixed';
//import './interactive/HamburgerMenu';
//import './interactive/SearchBox';
//import './interactive/Accordion';
//import './interactive/Tab';
//import './interactive/Modal';
//import './interactive/Carousel';
//import './interactive/Validate';

(function ($) {
    "use strict";
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
    var rtsJs = {
        m: function (e) {
            rtsJs.d();
            rtsJs.methods();
        },
        d: function (e) {
            (this._window = $(window)), (this._document = $(document)), (this._body = $("body")), (this._html = $("html"));
        },
        methods: function (e) {
            rtsJs.magicCoursor();
            rtsJs.paragraphBodyAnimation();
            rtsJs.SplitHead();
            rtsJs.backToTopInit();
        },
        magicCoursor: function () {
            var myCursor = $(".rts-cursor");
            if (myCursor.length) {
                if ($("body").length) {
                    const e = document.querySelector(".cursor-inner"),
                        t = document.querySelector(".cursor-outer");
                    var n,
                        i = 0,
                        W = 0,
                        intro = 0,
                        o = !1;
                    // if($('.xoxo_fn_intro').length){intro=1;}
                    var buttons = "a, button, .active-progress";
                    var remove_cursor = ".learn-more-btn";
                    // link mouse enter + move
                    (window.onmousemove = function (s) {
                        o || (t.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)"), (e.style.transform = "translate(" + s.clientX + "px, " + s.clientY + "px)"), (n = s.clientY), (i = s.clientX);
                    }),
                        $("body").on("mouseenter", buttons, function () {
                            e.classList.add("cursor-hover"), t.classList.add("cursor-hover");
                        }),
                        $("body").on("mouseleave", buttons, function () {
                            ($(this).is("a") && $(this).closest(".cursor-link").length) || (e.classList.remove("cursor-hover"), t.classList.remove("cursor-hover"));
                        }),
                        (e.style.visibility = "visible"),
                        (t.style.visibility = "visible");
                    // slider mouse enter
                    var mightyBody = jQuery("body");
                    mightyBody
                        .on("mouseenter", remove_cursor, function () {
                            e.classList.add("cursor-remove");
                            t.classList.add("cursor-remove");
                        })
                        .on("mouseleave", remove_cursor, function () {
                            e.classList.remove("cursor-remove");
                            t.classList.remove("cursor-remove");
                        });
                }
            }
        },
        SplitHead: function() {
            $(document).ready(function() {
                const headTitle = gsap.utils.toArray(".head-split");

                headTitle.forEach((section, index) => {
                    let heading = section.querySelector("h1");
                    //console.log(heading)
                    
                    let split = SplitText.create(heading, { type: "words" });
                    
                    //create an animation for each heading
                    let animation = gsap.from(split.words, { y: 100, opacity: 0, stagger: 0.1 });
                    
                        ScrollTrigger.create({
                        trigger: section,
                        start: "top 90%",
                        toggleActions: "play none none reverse",
                        animation: animation,
                        markers: false
                    });
                });
            });
        },
        paragraphBodyAnimation: function() {
            $(document).ready(function() {
                let splitTextLines = gsap.utils.toArray(".rts-text-anim p, .rts-text-anim");
        
                splitTextLines.forEach(splitTextLine => {
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: splitTextLine,
                            start: 'top 90%',
                            duration: 2,
                            end: 'bottom 20%',
                            //scrub: true,
                            markers: false,
                            toggleActions: "restart pause resume reverse",
                        }
                    });
        
                    const itemSplitted = new SplitText(splitTextLine, {
                        type: "lines",
                        linesClass: "split-line",
                    });
                    gsap.set(splitTextLine, {
                        //perspective: 400
                    });
                    itemSplitted.split({
                        type: "lines"
                    })
                    tl.from(itemSplitted.lines, {
                        duration: 1,
                        // delay: 0,
                        opacity: 0,
                        // rotationX: -0,
                        // force3D: true,
                        // transformOrigin: "top center 30",
                        stagger: 0.3,
                        duration: 0.6,
                        ease: "circ.out",
                        y: 100,
                        stagger: 0.02,
                    });
                });
        
            });
        },
        backToTopInit: function () {
            $(document).ready(function () {
                "use strict";

                var progressPath = document.querySelector(".progress-wrap path");
                var pathLength = progressPath.getTotalLength();
                progressPath.style.transition = progressPath.style.WebkitTransition = "none";
                progressPath.style.strokeDasharray = pathLength + " " + pathLength;
                progressPath.style.strokeDashoffset = pathLength;
                progressPath.getBoundingClientRect();
                progressPath.style.transition = progressPath.style.WebkitTransition = "stroke-dashoffset 10ms linear";
                var updateProgress = function () {
                    var scroll = $(window).scrollTop();
                    var height = $(document).height() - $(window).height();
                    var progress = pathLength - (scroll * pathLength) / height;
                    progressPath.style.strokeDashoffset = progress;
                };
                updateProgress();
                $(window).scroll(updateProgress);
                var offset = 50;
                var duration = 550;
                jQuery(window).on("scroll", function () {
                    if (jQuery(this).scrollTop() > offset) {
                        jQuery(".progress-wrap").addClass("active-progress");
                    } else {
                        jQuery(".progress-wrap").removeClass("active-progress");
                    }
                });
                jQuery(".progress-wrap").on("click", function (event) {
                    event.preventDefault();
                    jQuery("html, body").animate({ scrollTop: 0 }, duration);
                    return false;
                });
            });
        },
    };
    rtsJs.m();

    $("#discussBTN").click(function() {
        $('html,body').animate({
            scrollTop: $("#letsDiscuss").offset().top -50},
            'slow', 'swing');
    });
})(jQuery, window);